import { Component, EventEmitter, OnInit, Output,Input, ViewChild } from '@angular/core';
import { SearchAutocompleteComponent} from '../../components/search-autocomplete/search-autocomplete.component'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CaseReasonModel } from '../../pages/customer-care/case-reason/model/case-reason-model';
import { CaseReasonService } from '../../pages/customer-care/case-reason/service/case-reason.service';
import { GenericQuickcaseService} from '../generic-quickcase/generic-quickcase.service'
import { CasesModel } from '../../pages/customer-care/cases/model/cases-model';
import { ToastrService } from 'ngx-toastr';
import { IfStmt } from '@angular/compiler';
import { CommonListServicesService } from '../../services/common_list_service/common-list-services.service';
import { CasesService } from '../../pages/customer-care/cases/service/cases.service';
import { throwIfEmpty } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic-quickcase',
  templateUrl: './generic-quickcase.component.html',
  styleUrls: ['./generic-quickcase.component.scss']
})
export class GenericQuickcaseComponent implements OnInit {
  @Output() onCancel = new EventEmitter<any>()
  @Output() getlist: any = new EventEmitter();
  @Input() QuickCaseFlag;
  @Input() QuickCaseFlag2;
  @Input() InternalCase;
  @Input() customerName;
  @Input() id;
  @Input() serviceareaid : number;
  public subscriberList: string;
  public staffList: string;
  public caseReason: CaseReasonModel;
  public leaselinesubscriberList: string;
  public baseSetupList: string;
  listTemp: any = [];
  serviceAreaList : any =[];
  serviceAreaId : any;
  teamId: any;
  etr:number
  etrUnit:string
  teamType : any;
  allocationTypeList : any =[];
  algorithmTypeList : any = [];
  TeamTypeList : any =[];
  teamList : any = [];
  circuit: any = []
  form: FormGroup;
  custId: any;
  default_value: any;
  error_assignto: boolean;
  submitted: boolean = false;
  public error_customer: boolean;
  isTitleChecked: boolean=false;
  disableSave:boolean = false;
  public entity: CasesModel = new CasesModel();
  @ViewChild(SearchAutocompleteComponent, { static: false }) autoCompleteRef: SearchAutocompleteComponent;
  custIdd: any;
  disableflag: boolean = false;
  circuitIdPass:any;
  chargableType:any;
  Internalcase:boolean
  constructor(
    public caseReasonService: CaseReasonService,
    public GenericQuickcaseService:CasesService,
    private commonListService: CommonListServicesService,
    private toastrService: ToastrService,
    private router: Router,) { }

  ngOnInit() {
    let URL  = this.router.url.includes('casesInternal')  
    if(URL){
      this.Internalcase = true
    }else{
      this.Internalcase = false
    }
    this.form = new FormGroup({
      caseTitle: new FormControl(''),
      caseFor: new FormControl(''),
      caseType: new FormControl(''),
      customersId: new FormControl(null),
      leaseLineId: new FormControl(null),
      baseSetupId: new FormControl(null),
      caseReasonId: new FormControl('', [Validators.required]),
      caseOrigin: new FormControl(''),
      etr:new FormControl(null),
      etrUnit:new FormControl(null),
      priority: new FormControl(''),
      circuitId: new FormControl(null),
      propertyId:new FormControl(null),
      chargableType:new FormControl('', [Validators.required]),
      firstRemark: new FormControl('', [Validators.required]),
     // currentAssigneeId: new FormControl(null,Validators.required),
      //serviceareaid: new FormControl(null,Validators.required),
      // teamType: new FormControl(null,Validators.required),
      // allocationType: new FormControl(null,Validators.required),
      // algorithmType: new FormControl(null,Validators.required),
      // teamId: new FormControl(null,Validators.required),
      //bucketType: new FormControl(null)
    });
    this.getcaseReason();
    this.getchargeableType();
    if(this.QuickCaseFlag === 'Lease Line'){
    
      this.getleaselineSubscriber();
      //this.getstaff();
      this.getServiceArea();
      this.defaultSelectionAssignment();
  
      this.form.get('customersId').clearValidators();
      this.form.get('customersId').updateValueAndValidity();
      this.form.get('leaseLineId').setValidators([Validators.required]);
      this.form.get('leaseLineId').updateValueAndValidity();
      this.form.get('circuitId').setValidators([Validators.required]);
      this.form.get('circuitId').updateValueAndValidity();
      this.form.get('caseFor').setValue('Lease Line');
      //this.form.get('serviceareaid').setValidators(Validators.required);
      // this.form.get('teamType').setValidators(Validators.required);
      // this.form.get('allocationType').setValidators(Validators.required);
      // this.form.get('algorithmType').setValidators(Validators.required);
      // this.form.get('teamId').setValidators(Validators.required);
      //this.form.get('currentAssigneeId').setValidators(Validators.required);
      this.default_value = this.customerName;
    } else if(this.QuickCaseFlag === 'Customer'){
      
      this.getSubscriber();
      this.form.get('customersId').setValidators([Validators.required]);
      this.form.get('customersId').updateValueAndValidity();
      this.form.get('leaseLineId').clearValidators();
      this.form.get('leaseLineId').updateValueAndValidity();
      this.form.get('circuitId').clearValidators();
      this.form.get('circuitId').updateValueAndValidity();
      this.form.get('caseFor').setValue('Customer');
      //this.form.get('serviceareaid').setValidators(null);
      // this.form.get('teamType').setValidators(null);
      // this.form.get('allocationType').setValidators(null);
      // this.form.get('algorithmType').setValidators(null);
      // this.form.get('teamId').setValidators(null);
     // this.form.get('currentAssigneeId').setValidators(null);
      this.default_value = this.customerName;
    } else if(this.QuickCaseFlag2 === 'Base Setup'){
      this.getBaseSetup();
      this.form.get('baseSetupId').setValidators([Validators.required]);
      this.form.get('baseSetupId').updateValueAndValidity();
      this.form.get('customersId').clearValidators();
      this.form.get('customersId').updateValueAndValidity();
      this.form.get('leaseLineId').clearValidators();
      this.form.get('leaseLineId').updateValueAndValidity();
      this.form.get('circuitId').clearValidators();
      this.form.get('circuitId').updateValueAndValidity();
      this.form.get('caseFor').setValue('Base Setup');
      //this.form.get('serviceareaid').setValidators(null);
      // this.form.get('teamType').setValidators(null);
      // this.form.get('allocationType').setValidators(null);
      // this.form.get('algorithmType').setValidators(null);
      // this.form.get('teamId').setValidators(null);
     // this.form.get('currentAssigneeId').setValidators(null);
      this.default_value = this.customerName;
      
    } else if(this.QuickCaseFlag == 'Ring'){
   
        this.form.get('caseFor').setValue('Ring');
        this.disableflag = true;
        this.isTitleChecked=true;
        //this.form.get('serviceareaid').setValidators(null);
        // this.form.get('teamType').setValidators(null);
        // this.form.get('allocationType').setValidators(null);
        // this.form.get('algorithmType').setValidators(null);
        // this.form.get('teamId').setValidators(null);
       // this.form.get('currentAssigneeId').setValidators(null);
    }
    else if(this.QuickCaseFlag == 'Internal'){
      this.form.get('caseFor').setValue('Internal');
      this.disableflag = true;
      this.isTitleChecked=true;
      //this.form.get('serviceareaid').setValidators(null);
      // this.form.get('teamType').setValidators(null);
      // this.form.get('allocationType').setValidators(null);
      // this.form.get('algorithmType').setValidators(null);
      // this.form.get('teamId').setValidators(null);
      //this.form.get('currentAssigneeId').setValidators(null);
  } 
  }

  public getBaseSetup() {
    this.baseSetupList = 'baseSetup/searchBaseSetup?s=';
  }

  public getSubscriber() {
    this.subscriberList = 'subscriber/searchNew?s=';
  }

  getchargeableType(){
    this.commonListService.chargableType().subscribe((res) => {
    this.chargableType = res.dataList;
    });
   }

  public getleaselineSubscriber() {
    this.leaselinesubscriberList = 'leasedlinesubscriber/searchLeaseCustomer?s=';
  
  }

  public getcaseReason() {
    if( this.Internalcase == true){
      this.caseReasonService.getcaseReasonInternal().subscribe((res) => {
        this.caseReason = res.dataList;
      });
    }
    else{
      this.caseReasonService.getcaseReasonCustomer().subscribe((res) => {
        this.caseReason = res.dataList;
      });
    }
  }

  get f() {
    return this.form.controls;
  }

  getCircuit() {
    this.GenericQuickcaseService.getcircuitdetails(this.custId).subscribe((res) => {
      if(res && res.dataList)
        this.circuit = res.dataList;
      // res.dataList.forEach((ls) =>{
      //   this.form.get('propertyId').setValue(ls.propertyLine);
      // });
    });
  }

  public setSubscriber(eventcust) {
    let event = eventcust.event;
    this.custIdd = eventcust.event.custid;
    if (event) {
      this.form.get('customersId').setValue(event.custid);
      this.error_customer = true;

    } else {
      this.error_customer = false;
      this.form.get('customersId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
  }
  public setdefaultSubscriber() {
    
    if (this.id) {
      this.form.get('customersId').setValue(this.id);
      this.error_customer = true;

    } else {
      this.error_customer = false;
      this.form.get('customersId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
    if(this.id !== null){
      this.disableflag = true;
    } else {
      this.disableflag = false;
    }
  }

  public setBaseSubscriber(eventcust) {
    let event = eventcust.event;
    this.custIdd = eventcust.event.custid;
    if (event) {
      this.form.get('baseSetupId').setValue(event.custid);
      this.error_customer = true;

    } else {
      this.error_customer = false;
      this.form.get('baseSetupId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
  }

  public setdefaultBaseSubscriber() {
    
    if (this.id) {
      this.form.get('baseSetupId').setValue(this.id);
      this.error_customer = true;

    } else {
      this.error_customer = false;
      this.form.get('baseSetupId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
    if(this.id !== null){
      this.disableflag = true;
    } else {
      this.disableflag = false;
    }
  }

  public setdefaultLeaseSubscriber() {
    if (this.id) {
      this.custId = this.id;
      this.form.get('leaseLineId').setValue(this.id);
      this.error_customer = true;
      this.getCircuit();
      
      
    } else {
      this.error_customer = false;
      this.form.get('leaseLineId').reset();
      this.autoCompleteRef.autocomplete.clearAllText;
      this.autoCompleteRef.dataList = [];
    }
    if(this.id !== null){
      this.disableflag = true;
    } else {
      this.disableflag = false;
    }
  }

  // public getstaff() {
  //   this.staffList = 'staff/search?s=';
  // }

  // public setstaff(eventstaff) {
  //   let event = eventstaff.event
  //   if (event === null) {
  //     this.error_assignto = true;
  //     this.entity.currentAssigneeId = null;
  //   }else{
  //     this.error_assignto = false;
  //   }
  //   this.entity.currentAssigneeId = event.id;
  // }

  close(){
    this.onCancel.emit();
  }

  caseReasonChange(event) {
    if(event){
      this.form.get('caseTitle').setValue(event.name);
        this.form.get('etr').setValue(event.etr)
        this.form.get('etrunit').setValue(event.etrUnit)
    }
    // if(event && event.bucket){
    //   this.form.get('bucketType').setValue(event.bucket);
    //   // this.form.get('bucketType').disable();
    //   // this.form.get('bucketType').updateValueAndValidity();
    // }else{
    //   this.form.get('bucketType').reset();
    //   // this.form.get('bucketType').enable();
    //   // this.form.get('bucketType').updateValueAndValidity();
    // }
  }
  OncircuitChange(event){
    if(event){
      this.entity.serviceAreaId = event.serviceAreaId ? event.serviceAreaId : -1;
      this.form.get('propertyId').setValue(event.propertyLine); 
      this.form.get('circuitId').setValue(event.id)
    }
  }
  public setLeaseSubscriber(eventcust) {
    let event = eventcust.event;
    if(!event){
      this.form.get('circuitId').enable();
      this.entity.circuitId=null;
    }
    this.custId = event.custid;
     if (event) {
        this.entity.serviceAreaId = event.serviceAreaId ? event.serviceAreaId : -1;
        this.form.get('leaseLineId').setValue(event.custid);
        this.error_customer = true;
        this.getCircuit();
        this.entity.circuitId=event.circuitPrimaryId;
         this.circuitIdPass=event.circuitPrimaryId;
         this.form.get('propertyId').setValue(event.propertyPrimaryId); 
         this.form.get('circuitId').disable();
        // this.form.get('circuitId').setValue(event.circuitPrimaryId)
        this.form.updateValueAndValidity();
       } else {
        this.error_customer = false;
        this.form.get('circuitId').enable();
        this.form.get('leaseLineId').reset();
        this.autoCompleteRef.autocomplete.clearAllText;
        this.autoCompleteRef.dataList = [];
      }
     

  }

  onChangeChkTitle(event){
    if(event.target.checked===true){
      this.isTitleChecked=true;
    }
    else{
      this.isTitleChecked=false;
    }
  }

  validateForm() {
    this.submitted = true;

    if (this.form.invalid) {
      this.toastrService.error("Please fill all the details!");
      return
    }
    else {
      // if(this.QuickCaseFlag == 'Lease Line' && this.entity.currentAssigneeId==null){
      //   this.error_assignto = true;
      //   return
      // }
      // else{
      //   this.error_assignto = false;
      //   this.form.get('currentAssigneeId').setValue(this.entity.currentAssigneeId);
        
        this.saveQuickCase();
    // }
  }
  }
  saveQuickCase() {
    
    if (this.form.valid === true) {
      this.disableSave = true
      this.form.get('caseType').setValue('Issue');
      this.form.get('caseOrigin').setValue('Phone');
       this.form.get('priority').setValue('Medium');
       this.etr =this.form.get('etr').value;
       this.etrUnit=this.form.get('etrUnit').value;
      // this.form.get('teamType').setValue(this.teamType);
      if(this.isTitleChecked==true){
        let firstRemark = this.form.get('firstRemark');
        this.form.get('caseTitle').setValue(firstRemark.value);
      }
    if(!this.id)
      this.form.get('circuitId').setValue(this.circuitIdPass)
    
      let InputData=this.form.getRawValue();
    
      // this.form.value (old value) changed
      // InputData (new Changes Due to circuitId)
      this.GenericQuickcaseService.saveCase(InputData).subscribe((res) => {
       
        if (res.responseCode === 200) {
          //this.btnDisabledFlag = false;
          this.getlist.emit();
          this.toastrService.success("Case saved successfully");
          this.close();
          this.form.get('customersId').reset();
          this.form.get('leaseLineId').reset();
          this.form.get('baseSetupId').reset();
          this.autoCompleteRef.autocomplete.clearAllText;
          this.autoCompleteRef.dataList = [];
          
          //$("#quickCaseModal").modal('hide');
          this.form.reset();
          this.disableSave = false;
        }
        else if (res.responseCode !== 200) {
          this.toastrService.error(res.responseMessage);
          this.disableSave = false;
        }
       
        
       
      });
    } else {
      this.toastrService.error("Form is not valid!");
    }
  }

  getServiceArea(){
    this.caseReasonService.getAllServiceArea().subscribe((res)=>{
      this.serviceAreaList = res.dataList;
    });
  }

  // getAllocationType(){
    
  //   this.commonListService.getGenericCommonList('allocationType').subscribe((res) => {
  //     this.allocationTypeList = res.dataList
  //   })
  // }

  // getTeamList(){
 
  //   let obj = {
  //     serviceAreaIdList : [],
  //     teamType : this.teamType
  //   }
  //   obj.serviceAreaIdList.push(this.serviceAreaId)
  //   this.caseReasonService.getTeamList(obj).subscribe((res)=>{

  //     this.teamList = res.dataList;
  //   })
  // }

  // getAlgorithmType(){
  //   this.commonListService.getGenericCommonList('algorithmType').subscribe((res) => {
  //     //this.algorithmTypeList = res.dataList;
  //     this.algorithmTypeList = res.dataList.filter(item => item.value == 'Least Allocation' );
  //   })
  // }
  defaultSelectionAssignment(){
    //this.onChngServcArea(this.entity.serviceAreaId)
   // this.entity.teamType = 'Back Office';
    //this.entity.allocationType = 'Manual';
   // this.getTeamList();
    // setTimeout(() => {
    //   //console.log("teamList",this.teamList);
    //   // if(this.teamList.length > 0){
    //   //   this.entity.teamId = this.teamList[0].id
    //   //   //this.onChangeTeam(this.teamList[0]);
    //   // }
    // }, 2000);
   
  }

  // onChngServcArea(event){
  //   // this.TeamTypeList = null;
  //   // this.allocationTypeList = null;
  //   // this.form.get('teamType').reset();
  //   // this.form.get('teamType').updateValueAndValidity();
  //   // this.form.get('allocationType').reset();  this.form.get('allocationType').updateValueAndValidity();
  //   if(event != undefined){
  //     this.serviceAreaId = event;
  //     this.caseReasonService.getTeamType(this.serviceAreaId).subscribe((res) => {
  //     this.TeamTypeList = res.dataList;  
  //   }
  //   )
  //  }
  // }

  // onChangeTeamType(event){
  //   this.allocationTypeList = null;
  //   this.form.get('allocationType').reset();
  //   this.form.get('allocationType').updateValueAndValidity();
  //   if(event){
  //     this.teamType = event;
  //     this.getAllocationType();
  //   }
  // }


  // onChangeAllocType(event){
  //   this.form.get('algorithmType').reset();
  //   this.form.get('algorithmType').updateValueAndValidity();
  //   this.form.get('teamId').reset();
  //   this.form.get('teamId').updateValueAndValidity();
  //   this.form.get('currentAssigneeId').reset();
  //   this.form.get('currentAssigneeId').updateValueAndValidity();
  //   if(event == 'Auto'){
  //     this.form.get('teamId').clearValidators();
  //     this.form.get('teamId').updateValueAndValidity();
  //     this.form.get('currentAssigneeId').clearValidators();
  //     this.form.get('currentAssigneeId').updateValueAndValidity();
  //     this.form.get('algorithmType').setValidators(Validators.required)
  //     this.form.get('algorithmType').updateValueAndValidity();
  //     this.getAlgorithmType();
  //   }
  //   else{
  //     this.form.get('teamId').setValidators(Validators.required)
  //     this.form.get('teamId').updateValueAndValidity();
  //     this.form.get('currentAssigneeId').setValidators(Validators.required)
  //     this.form.get('currentAssigneeId').updateValueAndValidity();
  //     this.form.get('algorithmType').clearValidators();
  //     this.form.get('algorithmType').updateValueAndValidity();
  //     this.getTeamList();
  //   }
  // }

  // onChangeTeam(event){
  //   this.staffList = null;
  //   this.form.get('currentAssigneeId').reset();
  //   this.form.get('currentAssigneeId').updateValueAndValidity();
  //   if(event != undefined){
   
  //         this.staffList = event.teamUserMapping.filter(v=>v.isAssignable===true)
      
  //   }
  // }
}
