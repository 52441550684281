import { Component, OnInit, Input } from '@angular/core';
import { CustomModalService } from '../../../../components/custom-modal';

import { NetworkDetailsService } from './network-details.service';

import { NetworkDeviceServices } from '../../../../services/network_device/network_device.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as alertify from 'alertifyjs';
import _ from 'underscore';
import { CommonListServicesService } from '../../../../services/common_list_service/common-list-services.service';
import { ToastrService } from 'ngx-toastr';
import { AddSubscriberDetailsService } from '../../add-subscriber-details/add-subscriber-details.service';
import { AllocationIpSelectService } from '../allocation-ip-select/allocation-ip-select.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-network-details',
  templateUrl: './network-details.component.html',
  styleUrls: ['./network-details.component.scss']
})
export class NetworkDetailsComponent implements OnInit {
  ApicallChange:string="Network"
  @Input() custId: any;
  @Input() partnerId: number;
  networkDetailsData: any = {};
  oltSlotListAllData: any;
  submitted: any = null;
  remarks: any = "";
  selectedFormData: any = {};
  public networkTypeList: any;
  req_message = "This field is required.";
  snmpEnabled:string;
  serviceType:any;
  ConnectionType:any;
  RouterType:any;
  connTypeFree:any;
  public connectionTypeList:any[]=[];
  public routerTypeList:any[]=[];
  public bridgeTypeList:any[]=[];
  dynamicrequiredArray: any;
  openSelectIP: boolean = false;
  
  ipvalue:any;
  currentAllocatedId:any;
  currentChargeId:any;
  currentPoolDetailsId:any;
  allocationIpSelectData:any;
  poolDetailsId:any;
  poolId:any;
  hideipSelect:boolean=false;
  bridgeTypeObj:any;
  connTypeFreeList: any;
  selectIpPoolList: any[] = [
    {
      poolName: '',
      poolId: '',
    }
  ];

  public defaultPoolList: any[] = [
    {
      poolName: '',
      poolId: '',
    },
  ];

  public serviceAreaList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public networkDeviceList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public oltSlotList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public oltPortList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public serviceTypeList: any;
  nwTypeObj:any;
  serviceAreaUi:any;
  openAllocationIpSelectPopup: boolean = false;
  oltobj:any;
  insertNetworkDetailsForm = new FormGroup({
    network_type: new FormControl(null),
    defult_pool: new FormControl(null),

    service_area: new FormControl(null),
    olt: new FormControl(null),
    olt_slot: new FormControl(null),
    olt_port: new FormControl(null),
    connection_type: new FormControl(null),
    onuid: new FormControl(null),
    serviceType: new FormControl(null),
    routerType: new FormControl(null),
    remarks: new FormControl('', [Validators.required]),
    approvedBy: new FormControl(null),
    reason: new FormControl(null),
    ip_pool_id:new FormControl(null),
    select_ip:new FormControl(null),
    bridgeType:new FormControl(null),
    connTypeFree:new FormControl(null)
  })

  constructor(
    private customModalService: CustomModalService,
    private networkDetailsService: NetworkDetailsService,
    private networkDeviceServices: NetworkDeviceServices,
    private commonListService: CommonListServicesService,
    private tosterservice: ToastrService,
    private addSubscriberDetailsService: AddSubscriberDetailsService,
    private allocationIpSelectService: AllocationIpSelectService,
    private ngbmodalservice: NgbModal,
  ) {

    this.getRequiredAllField();
    
  }

  ngOnInit() {
    
    
    this.getNetworkDetailsbyCustId();
    this.getServiceTypeList();
    this.getConnectionTypeList();
    this.getRouterTypeList();
    this.getBridgeTypeList();
    this.getConnTypeFreeList();
  }
  getServiceTypeList() {
    this.commonListService.getGenericRemarkType('serviceType').subscribe((res) => {
      this.serviceTypeList = res.dataList;
    });
  }

  getConnTypeFreeList() {
    this.commonListService.getCommonListconnTypeFree().subscribe((res) => {
      this.connTypeFreeList = res.dataList;
    });
  }

  getConnectionTypeList(){
    this.commonListService.getGenericRemarkType('strconntype').subscribe((res) => {
      this.connectionTypeList = res.dataList;
    });
  }

  getRouterTypeList(){
    this.commonListService.getGenericRemarkType('routerType').subscribe((res) => {
      this.routerTypeList = res.dataList;
    });
  }
  getBridgeTypeList(){
    this.commonListService.getGenericRemarkType('bridgeType').subscribe((res) => {
      this.bridgeTypeList = res.dataList;
    });
  }


  getNetworkDetailsbyCustId() {
    
    let subscriber_id = this.custId;
    this.networkDetailsService.getNetworkDetailsbyCustId(subscriber_id).subscribe((result) => {
      this.networkDetailsData = result;
      
      this.networkTypeList = this.networkDetailsData.data.networkType;
      this.defaultPoolList = this.networkDetailsData.data.defaultPool;
      
      if(result.data.allocatedIP && result.data.allocatedIP.length !== 0){
          
          this.hideipSelect=true;
          this.ipvalue=result.data.allocatedIP[0].ipAddress;
        

          this.insertNetworkDetailsForm.get('select_ip').setValue(this.ipvalue);
          this.insertNetworkDetailsForm.get('select_ip').updateValueAndValidity();

          this.currentAllocatedId=result.data.allocatedIP[0].currentAllocatedId;
          this.currentChargeId=result.data.allocatedIP[0].currentChargeId;
          this.currentPoolDetailsId=result.data.allocatedIP[0].currentPoolDetailsId;
      }
      
      
      
      if(this.networkDetailsData.data.selectedNetworkDeviceDTO!=null){
        this.snmpEnabled=this.networkDetailsData.data.selectedNetworkDeviceDTO.snmpEnabled
      }
      
      // /**Changed */
      // this.defaultPoolList.forEach(data => {
      //   this.insertNetworkDetailsForm.patchValue({ defult_pool: data.poolId });
      //  })

     this.serviceAreaList = this.networkDetailsData.data.serviceArea;
     
     if(result.data.selectedNetworkType && result.data.selectedNetworkType==="IPOE"){
       this.hideipSelect=true;
     }else{
      this.hideipSelect=false;
     }

      this.selectedFormData = {
        network_type: result.data.selectedNetworkType,
        connection_type: result.data.selectedConnectionType,
        onuid: result.data.selectedOnuId,
        serviceType: result.data.selectedServiceType,
      };

      console.log("selecteddata--"+this.selectedFormData)
      
      if (result.data.selectedDefaultIpPool && result.data.selectedDefaultIpPool.poolId) {
        this.selectedFormData.defult_pool = result.data.selectedDefaultIpPool.poolId
      } else {
        this.selectedFormData.default_pool = null;
      }
      if (result.data.selectedServiceArea && result.data.selectedServiceArea.id) {
        this.selectedFormData.service_area = result.data.selectedServiceArea.id;
      }
      if (result.data.selectedNetworkDeviceDTO && result.data.selectedNetworkDeviceDTO.id) {
        this.selectedFormData.olt = result.data.selectedNetworkDeviceDTO.id;
      }
      if (result.data.selectedOltSlotDetailDTO && result.data.selectedOltSlotDetailDTO.id) {
        this.selectedFormData.olt_slot = result.data.selectedOltSlotDetailDTO.id;
      }
      if (result.data.selectedOltPortDetailsDTO && result.data.selectedOltPortDetailsDTO.id) {
        this.selectedFormData.olt_port = result.data.selectedOltPortDetailsDTO.id;
      }

      
      if(result.data.selectedBridgeType){
        this.insertNetworkDetailsForm.patchValue({ "bridgeType": result.data.selectedBridgeType });
        this.selectedFormData.bridgeType=result.data.selectedBridgeType;
      }
      // else{
      //   this.insertNetworkDetailsForm.get('bridgeType').clearValidators();
      //   this.insertNetworkDetailsForm.get('bridgeType').updateValueAndValidity();
      // }
      
      if (result.data.selectedConnectionType!=null) {
        this.selectedFormData.routerType = result.data.selectedRouterType;
        this.selectedFormData.approvedBy = result.data.approvedBy;
        this.selectedFormData.reason = result.data.reason;
        this.selectedFormData.connTypeFree = result.data.selectedConnectionTypeFree;
        this.onChangeConnectionType(result.data.selectedConnectionType);
      }
      if (this.selectedFormData.service_area) {
        this.onChangeServiceArea({ id: this.selectedFormData.service_area });
      }
      if (this.selectedFormData.olt) {
        this.onChangeOltGetOltSlotByNetworkDeviceId({ id: this.selectedFormData.olt });
      }
      if(this.selectedFormData && this.selectedFormData.onuid){
        this.selectedFormData.onuid = (this.selectedFormData.onuid).trim();
      }
      this.insertNetworkDetailsForm.patchValue(this.selectedFormData);
    });
  }

  onChangeServiceArea(service_area) {
    if (service_area) {
      this.snmpEnabled=null
      this.networkDetailsService.getNetworkDeviceByServiceId(service_area.id).subscribe((result) => {
        this.networkDeviceList = result.dataList;
        if (this.selectedFormData.olt && service_area.name === undefined) {
          this.insertNetworkDetailsForm.patchValue({ "olt": this.selectedFormData.olt });
          let temp =  this.networkDeviceList.find(res => res.id === this.selectedFormData.olt);
          this.onChangeOltGetOltSlotByNetworkDeviceId(temp)
        } else {
          this.insertNetworkDetailsForm.get("olt").reset();
          this.insertNetworkDetailsForm.get("olt_slot").reset();
          this.insertNetworkDetailsForm.get("olt_port").reset();
        }
      });
    } else {
      this.oltSlotListAllData = null;
      this.oltSlotList = [];
      this.insertNetworkDetailsForm.get("olt").reset();
      this.insertNetworkDetailsForm.get("olt_slot").reset();
      this.insertNetworkDetailsForm.get("olt_port").reset();
    }
  }

  onChangeConnectionType(event){
    
    if((event.value==='Router' || event==='Router') && this.RouterType.isMandatory===true){
      this.insertNetworkDetailsForm.get('routerType').setValidators(Validators.required);
      this.insertNetworkDetailsForm.get('routerType').updateValueAndValidity();
    }
    else if(event.value!=='Router' || event!=='Router'){
      this.insertNetworkDetailsForm.get('routerType').reset();
      this.insertNetworkDetailsForm.get('routerType').clearValidators();
      this.insertNetworkDetailsForm.get('routerType').updateValueAndValidity();
    }
    if((event.value==='Free' || event==='Free')){
      this.insertNetworkDetailsForm.get('approvedBy').setValidators(Validators.required);
      this.insertNetworkDetailsForm.get('reason').setValidators(Validators.required);
      this.insertNetworkDetailsForm.get('connTypeFree').setValidators(Validators.required);
      this.insertNetworkDetailsForm.get('approvedBy').updateValueAndValidity();
      this.insertNetworkDetailsForm.get('reason').updateValueAndValidity();
      this.insertNetworkDetailsForm.get('connTypeFree').updateValueAndValidity();
    }
    else if(event.value!=='Free' || event!=='Free'){
      this.insertNetworkDetailsForm.get('approvedBy').reset();
      this.insertNetworkDetailsForm.get('approvedBy').clearValidators();
      this.insertNetworkDetailsForm.get('reason').reset();
      this.insertNetworkDetailsForm.get('reason').clearValidators();
      this.insertNetworkDetailsForm.get('connTypeFree').reset();
      this.insertNetworkDetailsForm.get('connTypeFree').clearValidators();
      this.insertNetworkDetailsForm.get('approvedBy').updateValueAndValidity();
      this.insertNetworkDetailsForm.get('reason').updateValueAndValidity();
      this.insertNetworkDetailsForm.get('connTypeFree').updateValueAndValidity();
    }
    // || event.type==='bridgeType'
    if(event.value ==='Bridge/Converter'  && this.bridgeTypeObj.isMandatory === true){
      this.insertNetworkDetailsForm.get('bridgeType').setValidators(Validators.required)
      this.insertNetworkDetailsForm.get('bridgeType').updateValueAndValidity();
    }else if(event.value !=='Bridge/Converter'){
      this.insertNetworkDetailsForm.get('bridgeType').reset();
      this.insertNetworkDetailsForm.get('bridgeType').clearValidators();
      this.insertNetworkDetailsForm.get('bridgeType').updateValueAndValidity();
    }
  }
  changeBridgetype(val){
    if(val){
      this.insertNetworkDetailsForm.get('bridgeType').setValue(val.value);
    }else{
      this.insertNetworkDetailsForm.get('bridgeType').setValue(null);
    }
  }

  onClearConnectionType(){
    this.insertNetworkDetailsForm.get('routerType').reset();
    this.insertNetworkDetailsForm.get('routerType').clearValidators();
    this.insertNetworkDetailsForm.get('approvedBy').reset();
    this.insertNetworkDetailsForm.get('approvedBy').clearValidators();
    this.insertNetworkDetailsForm.get('reason').reset();
    this.insertNetworkDetailsForm.get('reason').clearValidators();
    this.insertNetworkDetailsForm.get('connTypeFree').reset();
    this.insertNetworkDetailsForm.get('connTypeFree').clearValidators();
    this.insertNetworkDetailsForm.get('routerType').updateValueAndValidity();
    this.insertNetworkDetailsForm.get('approvedBy').updateValueAndValidity();
    this.insertNetworkDetailsForm.get('reason').updateValueAndValidity();
    this.insertNetworkDetailsForm.get('connTypeFree').updateValueAndValidity();
    //
  }

  getRequiredAllField() {
    this.serviceType = null;
    this.ConnectionType = null;
    this.addSubscriberDetailsService.getRequiredAllField().subscribe(result => {
      
      if (result.responseCode === 200) {
        this.dynamicrequiredArray = result.data;
        this.serviceType = this.dynamicrequiredArray.find((e => e.fieldname === 'serviceType'));
        this.ConnectionType = this.dynamicrequiredArray.find((e => e.fieldname === 'connectionType'));
        this.RouterType = this.dynamicrequiredArray.find((e => e.fieldname === 'routerType'));
        this.connTypeFree = this.dynamicrequiredArray.find((e => e.fieldname === 'connTypeFree'));
        this.bridgeTypeObj = this.dynamicrequiredArray.find(e => e.fieldname === 'bridgeType');
        this.nwTypeObj = this.dynamicrequiredArray.find(e => e.fieldname === 'NWType');
        this.serviceAreaUi = this.dynamicrequiredArray.find(e => e.fieldname === 'ServiceArea');
        this.oltobj = this.dynamicrequiredArray.find(e => e.fieldname == 'OLT');
        
        if (this.oltobj && this.oltobj !== null && this.oltobj.isMandatory === true) {
          this.insertNetworkDetailsForm.get('olt').setValidators(Validators.required)
          this.insertNetworkDetailsForm.get('olt').updateValueAndValidity();
        }
        else {
          this.insertNetworkDetailsForm.get('olt').clearValidators()
          this.insertNetworkDetailsForm.get('olt').updateValueAndValidity();
        }

        if ((this.serviceType !== null && this.serviceType !== undefined) && this.serviceType.isMandatory === true) {
          this.insertNetworkDetailsForm.get('serviceType').setValidators(Validators.required)
          this.insertNetworkDetailsForm.get('serviceType').updateValueAndValidity();
        }
        else {
          this.insertNetworkDetailsForm.get('serviceType').clearValidators()
          this.insertNetworkDetailsForm.get('serviceType').updateValueAndValidity();
        }

        if ((this.ConnectionType !== null && this.ConnectionType !== undefined) && this.ConnectionType.isMandatory === true) {
          this.insertNetworkDetailsForm.get('connection_type').setValidators(Validators.required)
          this.insertNetworkDetailsForm.get('connection_type').updateValueAndValidity();
        }
        else {
          this.insertNetworkDetailsForm.get('connection_type').clearValidators()
          this.insertNetworkDetailsForm.get('connection_type').updateValueAndValidity();
        } 

        if ((this.connTypeFree !== null && this.connTypeFree !== undefined) && this.connTypeFree.isMandatory === true) {
          this.insertNetworkDetailsForm.get('connTypeFree').setValidators(Validators.required)
          this.insertNetworkDetailsForm.get('connTypeFree').updateValueAndValidity();
        }
        else {
          this.insertNetworkDetailsForm.get('connTypeFree').clearValidators()
          this.insertNetworkDetailsForm.get('connTypeFree').updateValueAndValidity();
        } 

        if ((this.bridgeTypeObj !== null && this.bridgeTypeObj !== undefined) && this.bridgeTypeObj.isMandatory === true) {
          this.insertNetworkDetailsForm.get('bridgeType').setValidators(Validators.required)
          this.insertNetworkDetailsForm.get('bridgeType').updateValueAndValidity();
        }
        else {
          this.insertNetworkDetailsForm.get('bridgeType').reset()
          this.insertNetworkDetailsForm.get('bridgeType').clearValidators()
          this.insertNetworkDetailsForm.get('bridgeType').updateValueAndValidity();
        }

      if ((this.nwTypeObj !== null && this.nwTypeObj !== undefined) && this.nwTypeObj.isMandatory === true) {
      this.insertNetworkDetailsForm.get('network_type').setValidators(Validators.required)
      this.insertNetworkDetailsForm.get('network_type').updateValueAndValidity();
       }
       else {
      this.insertNetworkDetailsForm.get('network_type').reset()
      this.insertNetworkDetailsForm.get('network_type').clearValidators()
      this.insertNetworkDetailsForm.get('network_type').updateValueAndValidity();
      }

    if ((this.serviceAreaUi !== null && this.serviceAreaUi !== undefined) && this.serviceAreaUi.isMandatory === true) {
        this.insertNetworkDetailsForm.get('service_area').setValidators(Validators.required)
        this.insertNetworkDetailsForm.get('service_area').updateValueAndValidity();
         }
    else {
        this.insertNetworkDetailsForm.get('service_area').reset()
        this.insertNetworkDetailsForm.get('service_area').clearValidators()
        this.insertNetworkDetailsForm.get('service_area').updateValueAndValidity();
     }
    }
    })
  }

  onChangeOltGetOltSlotByNetworkDeviceId(network_device_data) {
    if (network_device_data) {
      this.snmpEnabled=network_device_data.snmpEnabled;
      this.networkDeviceServices.getOltSlotByNetworkDeviceId(network_device_data.id).subscribe((result) => {
        this.oltSlotListAllData = result;
        this.oltSlotList = result.dataList;
        this.oltPortList = [];
        let  temp = this.oltSlotList.find(res=> res.id === this.selectedFormData.olt_slot)
        if(temp){
          this.insertNetworkDetailsForm.patchValue({ "olt_slot": this.selectedFormData.olt_slot});
          this.onChangeOltSlot(temp)
        }else{
          this.insertNetworkDetailsForm.get("olt_slot").reset();
          this.insertNetworkDetailsForm.get("olt_port").reset();
        }
      });
    } else {
      this.oltSlotListAllData = null;
      this.oltSlotList = [];
      this.insertNetworkDetailsForm.get("olt_slot").reset();
      this.insertNetworkDetailsForm.get("olt_port").reset();
    }
  }

  onChangeOltSlot(olt_slot_data) {
    if (olt_slot_data) {
      let olt_port = [];
      this.oltSlotListAllData.dataList.forEach(element => {
        if (element.id === olt_slot_data.id) {
          this.oltPortList = element.oltPortDetailsList;
        }
      });
      if (this.selectedFormData.olt_port) {
        this.insertNetworkDetailsForm.patchValue({ "olt_port": this.selectedFormData.olt_port });
      } else {
        this.insertNetworkDetailsForm.get("olt_port").reset();
      }
    } else {
      this.oltPortList = [];
      this.insertNetworkDetailsForm.get("olt_port").reset();
    }
  }

  onClickSaveNetworkDetailsForm() {
    
    if (this.insertNetworkDetailsForm.valid === true) {
      this.submitted = false;
      let self = this;
      alertify.confirm("Change Network Details", "Are You Sure You want to Change Network Details ?",
        function () {
          self.saveApiCall();
        },
        function () {

        });
    } else {
      this.submitted = true;
      this.insertNetworkDetailsForm.markAllAsTouched();
    }
  }

  saveApiCall() {
     
   
    let apiInputData = Object.assign({}, this.insertNetworkDetailsForm.value);
        
    if(this.hideipSelect){
      this.insertNetworkDetailsForm.get('select_ip').setValidators([Validators.required]);
      this.insertNetworkDetailsForm.get('select_ip').updateValueAndValidity();
    }else{
     this.insertNetworkDetailsForm.get('select_ip').clearValidators();
     this.insertNetworkDetailsForm.get('select_ip').updateValueAndValidity();
    }

    let formatedData: any = {};
    formatedData.custId = this.custId;
    formatedData.connectionType = apiInputData.connection_type;
    formatedData.defaultPool = apiInputData.defult_pool;
    formatedData.networkType = apiInputData.network_type;
    formatedData.olt = apiInputData.olt;
    formatedData.oltPort = apiInputData.olt_port;
    formatedData.oltSlot = apiInputData.olt_slot;
    formatedData.onuId = (apiInputData.onuid).trim();
    formatedData.remarks = apiInputData.remarks;
    formatedData.serviceArea = apiInputData.service_area;
    formatedData.serviceType = apiInputData.serviceType;
    formatedData.routerType = apiInputData.routerType;
    formatedData.approvedBy = apiInputData.approvedBy;
    formatedData.reason = apiInputData.reason;
    formatedData.connTypeFree = apiInputData.connTypeFree;
            
    formatedData.currentPoolDetailsId=this.currentPoolDetailsId;
    formatedData.currentAllocatedId=this.currentAllocatedId;
    formatedData.currentChargeId=this.currentChargeId;
    formatedData.newPoolDetailsId=this.poolDetailsId
    formatedData.newPoolId=this.poolId
    formatedData.bridgeType=apiInputData.bridgeType;
    
    this.networkDetailsService.updateNetworkDetails(formatedData).subscribe(
      (result) => {
        if (result.responseCode !== 200 && result.responseMessage) {
          this.tosterservice.error(result.responseMessage);
        } else {
          this.tosterservice.success('Network Details Save Successfully.');
          this.customModalService.close('network-details', result);
        }
      }, err => {
        this.tosterservice.error(err.error.status + " " + err.error.ERROR);
      }
    );
  }

  closeModal(id: string) {
    this.customModalService.close(id, { close_from: "close_button" });
    this.insertNetworkDetailsForm.reset();
  }

 onIpPoolListChange(){

 }



// openAllocationIpSelectPopupClick() {
//   if (this.openSelectIP === true) {
//     this.openAllocationIpSelectPopup = true;
//   }
//   else {
//     this.tosterservice.error("Please Select Purchase!");
//   }

// }

getSubscriberPurchasedIp() {

  if(this.partnerId){
    this.custId = this.partnerId
  }
 //changed api
  this.allocationIpSelectService.getIpPoolwithPartner(this.custId).subscribe(
    result => {
      this.selectIpPoolList = result.dataList;
    }
  );
}

openAllocationIpSelectPopupClick(content) {
  this.openAllocationIpSelectPopup = true;
  this.ngbmodalservice.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' })
}

closeAllocationIpSelectPopup(data) {
  if(data){
    
    this.allocationIpSelectData=data;
    this.ipvalue=this.allocationIpSelectData.selectedFreeIpData.ipAddress;
     this.insertNetworkDetailsForm.get('select_ip') .setValue(this.allocationIpSelectData.selectedFreeIpData.ipAddress);
    this.poolDetailsId=this.allocationIpSelectData.selectedFreeIpData.poolDetailsId;
    this.poolId=this.allocationIpSelectData.selectedFreeIpData.poolId; 
  }
  this.openAllocationIpSelectPopup = false;
  this.ngbmodalservice.dismissAll();
}

changeNetworkType(obj){
   if(obj.value==="IPOE"){
    this.hideipSelect=true;
    this.insertNetworkDetailsForm.get('select_ip').setValidators([Validators.required]);
    this.insertNetworkDetailsForm.get('select_ip').updateValueAndValidity();
  }else{
    this.hideipSelect=false;
    // this.insertNetworkDetailsForm.get('select_ip').reset();
    this.insertNetworkDetailsForm.get('select_ip').clearValidators();
    this.insertNetworkDetailsForm.get('select_ip').updateValueAndValidity();
  }
}

 clearIpselect(obj){
   this.hideipSelect=false;
   this.insertNetworkDetailsForm.get('network_type').reset();
   this.insertNetworkDetailsForm.get('select_ip').reset();
   this.insertNetworkDetailsForm.get('select_ip').clearValidators();
   this.insertNetworkDetailsForm.get('select_ip').updateValueAndValidity();
 }

}
